@font-face {
  font-family: 'IndieFlower';
  src: url(./fonts/IndieFlower-Regular.ttf);
}

body {
  margin: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 75vmin;
  pointer-events: none;
}

.App-logo-container {
  min-width: 50%;
}

.App-header {
  padding-top: 0;
  background-color: #658aa6f8;
  min-height: calc(100vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.title-container {
  font-family: 'IndieFlower';
  font-size: calc(16px + 2vmin);
  color: black;
  padding: .5em;
}

.title {
  padding: 0;
  margin: 0;
}

.book-section {
  background-color: #e2e2e2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #2b2b2b;
  padding: 3em 0;
}

.section-header {
  color: #2b2b2b;
  font-size: 2.5em;
  margin-top: 0px;
}

.book {
  /* Add shadows to create the "card" effect */
  color: #000;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  max-height: 70vh;
  max-width: 80vw;
  margin: 15px;
}

.book-description {
  /* Add shadows to create the "card" effect */
  color: #000;
  font-size: calc(1.5em);
  max-width: 400px;
  margin: 15px;
}

.book-cover {
  max-height: 70vh;
  max-width: 80vw;
}

.buy-button {
  background-color: #04AA6D; /* Green */
  border: none;
  border-radius: 5px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.container { 
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1440px;
  } 

.flex-grid {
  display: flex;
}
.col {
  flex: 1;
}

.footer {
  background-color: #2b2b2b;
  min-height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 50px 0;
}

.footer a {
  color: white;
  text-decoration: none; 
}

.footer-section {
  width: 33.33%;
}

.footer-links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.footer-links-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
}

.footer-contact {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.contact-phone {
  white-space:nowrap;
}

.footer-contact-method {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-social-icon {
  font-size: 1.5em;
  padding: .5em;
}

.footer-contact-icon {
  font-size: 1em;
  margin-right: .5em;
}

.footer-copy {
  margin-top: 2em;
}

.separator-primary {
  border-top: 3px solid #34522b;
  width: 33%;
  margin-bottom: 3em;
}